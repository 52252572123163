<template>
  <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <path d="M26.3809 10.4159C27.3798 10.8573 28 11.8477 28 12.9999C28 14.1521 27.3798 15.1425 26.3809 15.5839L4.44523 25.2843C3.51145 25.6972 2.4684 25.5047 1.72053 24.8152C0.964327 24.1174 0.647847 23.0416 0.895296 22.0088L2.82089 13.9615H13.6731C14.3369 13.9615 14.875 13.4234 14.875 12.7596C14.875 12.0958 14.3369 11.5577 13.6731 11.5577H2.70588L0.895296 3.99105C0.647847 2.95823 0.964327 1.8824 1.72053 1.18466C2.49293 0.472475 3.54061 0.315554 4.44523 0.715529L26.3809 10.4159Z" fill="#8BD118"/>
  </svg>
</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconSendMessaging",
        mixins: [icon_config],
    }
</script>

<style lang="sass" scoped>
.chat-send
  margin: 0 25px
  cursor: pointer
</style>
