<template>
  <div
    class="user-item"
  >
    <div class="user-item__info__row user-item__info__row__avatar">
      <div
        style="position: relative;border-radius: 50%; margin-top: 48px"
        class="user-item__avatar"
        :class="{'user-item__avatar_hidden': !isRollUp}"
        :style="{'background-image': `url(${currentLaborer.avatar.path})`}"
      >
        <p
          v-if="currentLaborer.isSelfEmployed"
          style="
    width: 30px;
    height: 30px;
    position: absolute;
    right: -10px;
    top: 4px;
    background-color: rgb(127, 192, 18);
    text-align: center;
    border-radius: 50%;
    color: white;
    line-height: 30px;
    font-weight: 900;
"
        >
          C
        </p>
      </div>
    </div>

    <div class="user-item__info">
      <div>
        <template v-if="isRollUp">
          <div
            style="
          display: flex; flex-direction: row; justify-content: flex-start; align-items: center;margin-bottom: 12px"
          >
            <!-- TODO пока нет на бэкенде убрать  display: none-->
            <div
              v-if="getTrustabilityLabel"
              class="user-item__info__reliability"
              style="font-size: 1em; margin-left: 24px; border-left: 1px solid rgba(0, 0, 0, 0.3); padding-left: 8px"
            >
              Надёжность: {{ currentLaborer.trustability }}
            </div>
          </div>
        </template>
      </div>
      <div class="user-item__info__line">
        <div
          class="user-item__info__title"
        >
          {{ fullNameLabel }}
          {{ currentLaborer.age ? `, ${currentLaborer.age} ${ageLabel}` : '' }}
        </div>
      </div>

      <template v-if="isRollUp">
        <div class="user-item__info__line">
          <div class="user-item__info__country">
            Гражданство: {{ currentLaborer.country.title }}
          </div>
          <!--        TODO пока нет на бэкенде убрать  display: none-->
          <div
            v-if="currentLaborer.isSelfEmployed"
            class="user-item__info__preview"
          >
            <AppButton
              id="dropDown-wrapper"
              class=""
              style="margin: 0;     padding: 4px 10px !important"
            >
              САМОЗАНЯТЫЙ
            </AppButton>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <span>
              <v-icon
                size="30"
                color="success"
              > mdi-star</v-icon>
            </span>
            <span style="font-size: 1.4em">
              {{ parseFloat(currentLaborer.rating.toFixed(2), 10) }}
            </span>
          </div>
        </div>

        <div class="user-item__info__line user-item__info__line__user-data">
          <div class="user-item__info__row">
            <div class="user-item__info__profession">
              Общий опыт:
              {{ currentLaborer.experienceHourNumber || 0 }} ч.
            </div>

            <div
              v-if=" !isWorkerList && currentLaborer.vacancyExperienceInHours >= 0"
              class="user-item__info__profession"
            >
              Опыт по профессии:
              {{ currentLaborer.vacancyExperienceInHours || 0 }} ч.
            </div>
          </div>

          <div
            v-if="!isWorkerList"
            class="user-item__info__row user-item__info__row__created"
          >
            <div class="user-item__info__created">
              Откликнулся:
            </div>
            <div class="user-item__info__created">
              {{ `${
                appDateUtil.offerDate(`${responseLaborer.createdAt}`)}
${appDateUtil.offerTime(`${responseLaborer.createdAt}`)}` }}
            </div>
          </div>
        </div>

        <!--        TODO пока нет на бэкенде убрать  display: none-->
        <div
          style="display: none"
          class="user-item__info__line user-item__info__line__user-experience"
        >
          <div class="user-item__info__profession">
            Опыт из резюме: 2,5 года.
          </div>
        </div>
        <div
          v-if="isInexperienced"
          class="user-item__info__line"
        >
          <div
            class="user-item__info__profession"
          >
            Соискатель не указал свой опыт
          </div>
        </div>
        <div
          v-for="(experience, index) in getCurrentLaborerImportedWorkExperience"
          v-else
          :key="index"
          class="user-item__info__line"
        >
          <div
            class="user-item__info__profession"
          >
            <strong>{{ experience.profession }}: </strong>
            {{ `${experience.period} ${experience.organizationList}` }}
          </div>
        </div>
      </template>

      <AppLoader
        v-if="isLoading"
        style="margin-top: 20px; margin-bottom: 20px"
      />
      <div
        v-if="!isWorkerList && !isLoading && showControlView && selectedStatus.value !== 'rejectedNumber'"
        class="user-item__info__line user-item__info__line__button-group"
        :class="{'user-item__info__line__button-group_hidden': !isRollUp}"
      >
        <!--        <div-->
        <!--          class="user-item__card__button user-item__card__button__dropper"-->
        <!--          @click="isRollUp = !isRollUp"-->
        <!--        >-->
        <!--          {{ isRollUp ? "Свернуть" : "Развернуть" }}-->
        <!--        </div>-->
        <template v-if="selectedStatus && selectedStatus.value && selectedStatus.value === 'respondNumber'">
          <AppButton
            class="user-item__card__button user-item__card__button__cancel"
            @click.native="$emit('reject', responseLaborer)"
          >
            Отказать
          </AppButton>
          <AppButton
            class="user-item__card__button"
            @click.native="$emit('accept', responseLaborer)"
          >
            Пригласить
          </AppButton>
        </template>
        <AppButton
          v-else
          class="user-item__card__button wb-orange-button"
          @click.native="$emit('reject', responseLaborer)"
        >
          Отменить приглашение
        </AppButton>
      </div>
      <div
        v-if="isWorkerList && !isLoading"
        class="user-item__info__line user-item__info__line__button-group"
        :class="{'user-item__info__line__button-group_hidden': !isRollUp}"
      >
        <template>
          <AppButton
            class="user-item__card__button user-item__card__button__cancel"
            @click.native="$emit('reject', responseLaborer)"
          >
            {{ getRejectLabel }}
          </AppButton>
          <v-btn
            v-if="[
              workerFilterList.WORKER_FILTER,
              workerFilterList.BLACK_LIST_FILTER,
            ].includes(workerFilter)"
            outlined
            rounded
            small
            color="success"
            @click.stop="$emit('accept', responseLaborer)"
          >
            {{ getSuccessLabel }}
          </v-btn>
          <!--          <AppButton-->
          <!--            v-if="[-->
          <!--              workerFilterList.WORKER_FILTER,-->
          <!--              workerFilterList.BLACK_LIST_FILTER,-->
          <!--            ].includes(workerFilter)"-->
          <!--            class="user-item__card__button"-->
          <!--            @click.native="$emit('accept', responseLaborer)"-->
          <!--          >-->
          <!--            {{ getSuccessLabel }}-->
          <!--          </AppButton>-->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/simple/AppButton'
import AppLoader from '@/components/AppLoader'
import { pluriaizeYears } from '@/utils/plural-util'
import AppDateUtil from '@/utils/dateUtl'

import {
  WORKER_FILTER, FAVORITE_FILTER, BLACK_LIST_FILTER
} from '@/components/worker/workerFilterList'

const userTrustability = [
  'Низкая',
  'Средняя',
  'Высокая',
]

export default {
  name: 'OfferItemChatUserCardHeader',
  components: { AppButton, AppLoader },
  props: {
    responseLaborer: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    selectedStatus: {
      type: Object,
      required: true
    },
    laborer: {
      type: Object
    },
    workerFilter: {
      type: String,
      default: null
    },
    isWorkerList: {
      type: Boolean,
      default: false
    },
    showControlView: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isRollUp: true,
      appDateUtil: AppDateUtil,
      workerFilterList: {
        WORKER_FILTER,
        FAVORITE_FILTER,
        BLACK_LIST_FILTER,
      }
    }
  },
  computed: {
    getTrustabilityLabel () {
      if (this.currentLaborer.trustability === 'Сверхнадёжный') {
        return this.currentLaborer.trustability
      }

      return null
    },
    currentLaborer () {
      if (this.laborer) {
        return this.laborer
      }

      if (this.responseLaborer && this.responseLaborer.laborer) {
        return this.responseLaborer.laborer
      }
      return this.responseLaborer
    },
    getRejectLabel () {
      if (this.workerFilter === this.workerFilterList.WORKER_FILTER) {
        return 'В черный список'
      }
      if (this.workerFilter === this.workerFilterList.FAVORITE_FILTER) {
        return 'Убрать из команды'
      }
      return ''
    },
    getSuccessLabel () {
      if (this.workerFilter === this.workerFilterList.WORKER_FILTER) {
        return 'В мою команду'
      }
      if (this.workerFilter === this.workerFilterList.BLACK_LIST_FILTER) {
        return 'Убрать из черного списка'
      }
      return ''
    },
    isInexperienced () {
      return this.currentLaborer.isInexperienced
    },
    ageLabel () {
      return pluriaizeYears(this.currentLaborer.age)
    },
    fullNameLabel () {
      if (this.currentLaborer.fullName) {
        return this.currentLaborer.fullName
      }
      return `${this.currentLaborer.lastName} ${this.currentLaborer.firstName} ${this.currentLaborer.middleName || ''} `
    },
    getCurrentLaborerImportedWorkExperience () {
      const list = this.$store.state.offer.currentLaborerImportedWorkExperience
      const string = []

      if (list.length) {
        let curr
        for (const experience of list) {
          curr = {}
          curr.profession = `${experience.vacancyProfession.title}`
          curr.period = `${experience.period.title} `
          curr.organizationList = this.mapExperienceOrganizationList(experience.organizationList)
          string.push(curr)
        }
      }

      return string
    },
    parseTrustabilityValueToString () {
      const index = this.currentLaborer.trustability <= 33.3 ? 0
        : this.currentLaborer.trustability <= 66.6 ? 1
          : this.currentLaborer.trustability >= 66.7 ? 2 : 2
      return userTrustability[index]
    }
  },
  created () {
    if (!this.currentLaborer.isInexperienced) {
      this.$store.dispatch('offer/getLaborerImportedWorkExperience', { laborerId: this.currentLaborer.id })
    }
  },
  methods: {
    mapExperienceOrganizationList (organizationList) {
      if (organizationList.length) {
        return ` в ( ${organizationList.map((organization) => organization.title).join(', ')} )`
      }
      return ''
    },
  }
}
</script>

<style lang="sass" scoped>
  .user-item
    display: flex
    border-bottom: $default_border_bottom
    border-top: $default_border_bottom
    padding: 5px 10px
    &__avatar
      transition: .5s all
      height: 110px
      background-image: url("./../../assets/user-avatar-2.png")
      background-repeat: no-repeat
      background-position: center
      background-size: cover
      &_hidden
        height: 71px
        background-size: 71px

    &__info
      display: flex
      flex-direction: column
      flex-grow: 1
      &__row
        display: flex
        flex-direction: column
        &__avatar
          width: 110px
          margin-right: 25px
          flex-shrink: 0
        &__created
          margin-left: auto
      &__line
        display: flex
        align-items: center
        &__user-data
          margin-top: 20px
          padding-bottom: 10px
          border-bottom: $default_border_bottom
        &__button-group
          margin-top: 28px
          &_hidden
            border-top: $default_border_bottom
            margin-top: 0
            padding-top: 19px
        &__user-experience
          margin-top: 10px
      &__country
        @include fontStyle(500, 12px)
        color: rgba(0, 0, 0, 0.5)
        padding-right: 10px
        border-right: $default_border_bottom

      &__preview
        @include fontStyle(500, 12px)
        color: rgba(24, 98, 209, 0.5)
        margin-left: 10px

      &__reliability
        @include fontStyle(500, 10px)
        color: rgba(0, 0, 0, 0.5)

      &__message-count
        @include fontStyle(800, 12px)
        margin-left: auto
        background-color: #8BD118
        border-radius: 25px
        padding: 2px
        width: 18px
        height: 18px
        text-align: center
        color: white

      &__title
        @include fontStyle(800, 16px)
        margin-bottom: 10px

      &__profession
        @include fontStyle(600, 12px)
        color: rgba(0, 0, 0, 0.5)
        line-height: 20px

      &__created
        @include fontStyle(600, 12px)
        color: rgba(0, 0, 0, 0.3)
        text-align: right
        line-height: 20px

        &__count
          @include fontStyle(500, 12px)
          color: rgba(0, 0, 0, 0.5)

  .user-item__card__button
    &__dropper
      @include fontStyle(800, 14px)
      padding: 5px 15px 5px 0
      cursor: pointer
      text-decoration-line: underline
      color: rgba(0, 0, 0, 0.5)
      background-color: transparent
      box-shadow: 0 0 0 transparent

  .animation-enter-active, .animation-leave-active
    transition: opacity .5s

  .animation-enter, .animation-leave-to
    opacity: 0

</style>
