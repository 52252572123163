<template>
  <div
    class="offer-item__chat"
  >
    <OfferItemChatUserCardHeader
      v-if="selectedUser && showUserCard"
      :response-laborer="selectedUser"
      :isLoading="isLoading"
      :showControlView="isOffer"
      :is-worker-list="isWorkerList"
      :worker-filter="workerFilter"
      :selectedStatus="selectedStatus"
      @accept="(responseLaborer) => {this.$emit('accept', responseLaborer)}"
      @reject="(responseLaborer) => {this.$emit('reject', responseLaborer)}"
    />
    <div
      v-if="vacancyQuestionAnswerList.length"
      class="vacancy-question-wrapper"
    >
      <h4
        style="cursor: pointer; text-decoration: underline"
        class="vacancy-question-wrapper__title"
        @click="showVacancyQuestionAnswerList = !showVacancyQuestionAnswerList"
      >
        <IconClose
          v-if="showVacancyQuestionAnswerList"
          :with="20"
          :height="20"
        />
        <IconEye v-else />
        Ответы на предварительные вопросы:
      </h4>
      <div v-if="showVacancyQuestionAnswerList">
        <div
          v-for="(question, index) in vacancyQuestionAnswerList"
          :key="question.id"
          class="vacancy-question-wrapper__box"
        >
          <p class="vacancy-question-wrapper__question">
            {{ `${(index + 1)}. ${question.question}` }}
          </p>
          <p
            class="vacancy-question-wrapper__question"
          >
            {{ question.answer }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="isWorkerList"
      class="offer-item__chat_empty"
      style="padding: 16px; text-align: center"
    >
      <div v-if="!selectedUser || !respondents.length">
        <p style="color: black; size: 14px;line-height: 18px; font-weight: bold; margin: 24px">
          В этом разделе вы можете увидеть всех работников которые у вас работали.
        </p>
        <p style="color: black; size: 14px;line-height: 18px; margin: 24px">
          <IconTeamMember
            width="15"
            height="18"
          /> Вы можете заблокировать их, добавив в черный список, тогда они больше не увидят ваши офферы
        </p>
        <p style="color: black; size: 14px;line-height: 18px; margin: 24px">
          <IconTeamMember
            width="15"
            height="18"
            active
          /> Вы можете добавить их в свою команду, тогда при отклике
          на оффер они сразу будут записываться на смену в 1 клик
        </p>
      </div>
    </div>
    <div
      v-else
      class="offer-item__chat_empty"
    >
      <div
        v-if="!selectedUser || !respondents.length"
        class="offer-item__chat_empty"
      >
        <IconChat />

        {{
          canChat ?
            respondents.length ? "Выберите отклик" : "Откликов пока нет"
            : 'Оффер закрыт'
        }}
      </div>
      <template v-else>
        <div
          v-if="!Object.entries(messages).length"
          class="offer-item__chat_empty"
        >
          Напишите соискателю
        </div>
        <div
          v-else
          id="chat-scroll-container"
          class="offer-item__chat__messages"
        >
          <IconClose
            v-if="showUserCard"
            style="position: absolute; right: 2px; top: 3px;cursor:pointer;"
            :with="20"
            :height="20"
            :fill="'red'"
            @click="showUserCard = !showUserCard"
          />
          <IconEmployee
            v-else
            style="position: absolute; right: 2px; top: 3px;cursor:pointer;"
            :with="20"
            :height="20"
            @click="showUserCard = !showUserCard"
          />

          <div
            v-for="group in Object.keys(messages)"
            :key="group"
            class="offer-item__chat__messages__group"
          >
            <h4>{{ group }}</h4>
            <div
              v-for="mess in messages[group]"
              :key="mess.id"
              class="offer-item__chat__messages__item"
              :class="{'offer-item__chat__messages__item_other': mess.sender.id === currentLaborer.id,
                       'offer-item__chat__messages__item_me': mess.sender.id === user.id
              }"
            >
              <!--          <h2 class="message__header">-->
              <!--            {{ setFioNameChat(mess) }}-->
              <!--            <span class="message__time"> {{ `${mess.createdDate} в ${mess.createdTime}` }}</span>-->
              <!--          </h2>-->
              <p
                style=" word-break: break-all !important;"
                class="message__text"
                :style="{'color': mess.sender.id !== user.id ? 'white' : ''}"
              >
                {{ `${mess.message}` }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="!isWorkerList && canChat && laborer"
      class="offer-item__chat__bottom"
    >
      <div
        class="offer-item__chat__bottom__input"
        style="padding-bottom: 16px"
      >
        <!--        <AppFileInput-->
        <!--          v-if="laborer"-->
        <!--          class="chat-attach"-->
        <!--          @change="file = $event"-->
        <!--        />-->

        <AppInput
          v-if="laborer"
          v-model="message"
          placeholder="Введите сообщение..."
          class="item__chat__wrapper"
          input-class="offer-item__chat__input"
          :onKeyUp="keyUp"
        />

        <IconSendMessaging
          class="chat-send"
          @click="sendToLaborerByOffer"
        />
      </div>
      <div
        v-if="file"
        class="offer-item__chat__bottom__file-list"
      >
        <div class="file__item">
          <IconFile />
          <div>
            <p>{{ file.name }}</p>
            <p>{{ file.size | fileSizeFormat }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--    <div style="width: 100%">
      adsadasd
    </div>-->
  </div>
</template>

<script>
import IconChat from '@/components/common/icons/IconChat'
import AppInput from '@/components/common/simple/AppInput'
import IconSendMessaging from '@/components/common/icons/IconSendMessaging'
// import AppFileInput from '@/components/common/simple/AppFileInput'
import OfferItemChatUserCardHeader from '@/components/offer/OfferItemChatUserCardHeader'
import IconFile from '@/components/common/icons/IconFile'
import IconEye from '@/components/common/icons/IconEye'
import IconClose from '@/components/common/icons/IconClose'
import IconTeamMember from '@/components/common/icons/IconTeamMember'
import IconEmployee from '@/components/common/icons/IconEmployee'

export default {
  name: 'OfferItemChat',
  filters: {
    fileSizeFormat (val) {
      return val < 1000 ? `${val}B`
        : val <= 1000 || val >= 9999 ? `${(val / 1000).toFixed(2)}KB`
          : `${(val / 100000).toFixed(2)}MB`
    }
  },
  components: {
    IconClose,
    IconTeamMember,
    IconEmployee,
    IconEye,
    IconFile,
    OfferItemChatUserCardHeader,
    // AppFileInput,
    IconSendMessaging,
    AppInput,
    IconChat
  },
  props: {
    selectedUser: {
      type: Object,
    },
    selectedStatus: {
      type: Object,
      required: true
    },
    isOffer: {
      type: Boolean,
      default: true
    },
    workerFilter: {
      type: String,
      default: null
    },
    isWorkerList: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    respondents: {
      type: Array,
      required: true
    },
    offer: {
      type: Object
    }
  },
  data () {
    return {
      message: '',
      showVacancyQuestionAnswerList: false,
      showUserCard: true,
      file: ''
    }
  },

  computed: {
    currentLaborer () {
      if (this.selectedUser && this.selectedUser.laborer) {
        return this.selectedUser.laborer
      }
      return this.selectedUser
    },
    messages () {
      const currentOffer = this.selectedUser

      if (currentOffer && currentOffer.laborer && currentOffer.laborer.id) {
        const messages = this.$store.state.chat.messages[currentOffer.laborer.id]

        // messages = messages[this.currentLaborer.id]
        // if (messages) {
        //   return messages
        // }
        if (messages) {
          this.scrollToBottom()
          return messages
        }
      }
      return []
    },
    vacancyQuestionAnswerList () {
      return this.$store.state.chat.vacancyQuestionAnswerList
    },
    canChat () {
      const isActiveOffer = this.isOffer && ['active', 'casting'].includes(this.offer.status)
      return !this.isOffer || isActiveOffer
    },
    laborer () {
      return this.selectedUser && this.currentLaborer && this.currentLaborer.id
    },
    user () {
      return this.$store.state.users.info
    }
  },
  watch: {
    selectedUser (val) {
      if (val) this.getHistory()
    },
    messages: {
      deep: true,
      handler (val) {
        this.scrollToBottom()
        setTimeout(() => {
          this.scrollToBottom()
        }, 100)
      }
    },
  },
  created () {
    this.eventsBus.$on('scrollChat', () => {
      setTimeout(() => {
        this.scrollToBottom()
      }, 50)
    })
  },
  methods: {
    keyUp ($event, data) {
      if ($event.keyCode === 13) {
        this.sendToLaborerByOffer()
      }
    },
    // consumeMessage (data, remote = false) {
    //   if (remote && data.sender.own) {
    //     return null
    //   }
    //   const offerId = data.parentId
    //   const receiverId = data.sender.own ? data.receiver.id : data.sender.id
    //   const userMessageStore = this.$store.state.chat.messages
    //   const offerMessageList = userMessageStore[offerId] && Array.isArray(userMessageStore[offerId][receiverId])
    //     ? userMessageStore[offerId][receiverId]
    //     : []
    //   offerMessageList.unshift(data)
    //
    //   this.$store.commit('chat/setMessage', { message: offerMessageList, offerId, receiverId })
    //   this.scrollToBottom()
    // },
    getHistory () {
      if (!this.isWorkerList) {
        this.$store.dispatch('chat/fetchHistoryMessages',
          {
            contactId: this.currentLaborer.id,
            groupId: this.offer.id,
            localChatRoom: this.isOffer ? 'offer' : 'relay'
          })
        this.$store.dispatch('chat/getVacancyQuestionAnswer',
          { laborerId: this.currentLaborer.id, offerId: this.offer.id })
      }
    },
    sendToLaborerByOffer () {
      const { file } = this
      if (file) {
        this.sendFile()
          .then(({ id }) => this.sendMessage(id))
      } else {
        this.sendMessage()
      }
    },

    sendFile () {
      const { file } = this
      return this.$store.dispatch('uploadFile', file)
        .then((response) => {
          this.file = ''
          return response.data
        })
    },

    sendMessage (imageId) {
      const text = this.message
      if (!text || text.trim() === '') {
        return
      }
      this.message = ''
      const level = this.isOffer ? 'offer' : 'relay'
      const parentId = this.isOffer ? this.offer.id : this.offer.parent
      return this.$store.dispatch('chat/sendToLaborerByOffer',
        { receiverId: this.currentLaborer.id, text, imageId, offerId: parentId, level })
        .then((response) => {
          this.message = ''
          this.consumeMessage(
            {
              'message': text,
              'type': 'chat',
              'parentId': this.offer.id,
              'createdTime': this.$moment().format('H:m:s'),
              'createdDate': this.$moment().format('DD MMMM'),
              'createdAT': this.$moment().format('YYYY-MM-DD[T]HH:mm:ss'),
              'sender': {
                id: this.user.id,
                username: '',
                own: true,
              },
              'receiver': {
                id: this.currentLaborer.id,
                username: 'username',
              },
              ...response.data
            }
          )
        })
    }
  }
}
</script>

<style lang="sass" scoped>
@mixin chat_element_after()
  content: ''
  position: absolute
  width: 15px
  height: 15px
  background: #F6F6F6

@mixin chat_element_before()
  content: ''
  position: absolute
  z-index: 100000
  height: 20px
  background: #ffffff

.own-message
  color: #FFFFFF !important

.vacancy-question-wrapper
  width: 100%
  margin: 0 auto 8px auto
  padding: 2%
  border: 1.5px solid #8BD118

  &____box
    margin-top: 4px
    margin-bottom: 4px

  &__ttile
    font-weight: 800
    font-size: 12px
    line-height: 15px
    color: #000000
    opacity: 0.7
    cursor: pointer
    text-decoration: underline

  &__question
    font-weight: 600
    font-size: 12px
    line-height: 15px
    color: #000000
    opacity: 0.7
    margin-top: 8px
    margin-bottom: 8px

  &__answer
    font-weight: bold

.word-break
  word-wrap: break-word
  width: 90%
  border: 3px solid red
.message
  &__header
    text-align: left
    @include fontStyle(800, 12px)
    color: rgba(0, 0, 0, 0.8)

  &__time
    text-align: left
    @include fontStyle(500, 12px)
    color: rgba(0, 0, 0, 0.3)

  &__text
    text-align: left
    @include fontStyle(500, 12px)
    color: rgba(0, 0, 0, 0.7)

.offer-item
  &__chat
    display: flex
    flex-direction: column
    flex-grow: 1
    height: 100%

    &__messages
      display: flex
      flex-direction: column
      flex-grow: 1
      overflow-y: auto
      height: 1px

      &__item
        flex-basis: auto
        margin: 5px 38px
        border-radius: 0 5px 5px 5px
        padding: 8px 4px
        z-index: 1

        &_other
          margin-right: auto
          align-self: flex-start
          background-color: rgb(78, 140, 255)

        &_me
          margin-left: auto
          background-color: rgb(234, 234, 234)
          color: white !important

      &__group
        display: flex
        flex-direction: column
        flex-basis: auto
        margin: 5px 38px
        border-radius: 0 5px 5px 5px
        padding: 8px 4px
        z-index: 1
        text-align: center

    &_empty
      @extend .offer-item__chat
      @include fontStyle(500, 16px)
      color: rgba(0, 0, 0, 0.51)
      display: flex
      flex-grow: 1
      flex-direction: column
      justify-content: center
      align-items: center

    &__bottom
      display: flex
      flex-direction: column
      /*border-top: 1px solid rgba(0, 0, 0, 0.1)*/
      min-height: 40px
      max-height: 140px
      flex-shrink: 0

      &__input
        margin-top: 0
        margin-bottom: auto
        display: flex
        align-items: center
        width: 100%

      &__file-list
        display: flex
        margin: 10px 0 10px 25px

  .file__item
    display: flex

    p
      margin-left: 10px
</style>
